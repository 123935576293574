import React, { Component } from 'react';
import drawing from './drawing.svg';
import './App.css';

class App extends Component {
  render() {
    return (
      <div class="main container-fluid">
      <div class="col-md text-center">
        <h1 class="heading">asyncbox</h1>
        <h4 class="sub-heading">is asynchronous communication for teams</h4>
        <div class="col-md-12 image">
          <img src={drawing} alt="asyncbox" width="40%"/>
        </div>
        <div class="col-md-12">
          <a class="btn btn-primary" href="https://www.producthunt.com/upcoming/asyncbox" target="_blank" rel="noopener noreferrer">GET EARLY ACCESS</a>
        </div>
      </div>
    </div>
    );
  }
}

export default App;
